import React, { useState } from 'react';
import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Text } from '@web-for-marketing/react-ui';
import { roiResultStyles as classes } from './styleObjects/ROIResultStyles';
import { roiIndustryValueAtom, roiUnitSystemAtom } from '@atoms/roiCalculator';
import { useAtom } from 'jotai';
import { Duration, ROIIndustryOption, Unit } from '@models/ROICalculator';
import {
    getPdfResults,
    getROIPaybackTime,
    getROIResultByDuration,
    roundToLocalString,
    roundTwoDecimal,
} from './ROICalculationHelper';
import { capitalizeFirstLetter } from '@helpers/string';
import { PopoutButton } from '@components/PopoutCustomSection/PopoutButton';
import { generatePdf } from '@services/roiPdfService';
import { FormParamKeys } from '@models/form';

const generateROIPdf = async (industryValue: ROIIndustryOption, unitSystem: Unit): Promise<void> => {
    const pdfresult = getPdfResults(industryValue, unitSystem);
    const response = await generatePdf(pdfresult, 'roi');

    if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'ROI_Calculator_Results.pdf';
        document.body.appendChild(a);
        a.click();
        a.remove();
    } else {
        console.error('Failed to generate PDF');
    }
};

export default function ROIResult(): JSX.Element {
    const [industryValue] = useAtom(roiIndustryValueAtom);
    const [duration, setDuration] = useState(Duration.Month);
    const [unitSystem] = useAtom(roiUnitSystemAtom);
    const result = getROIResultByDuration(duration, industryValue, unitSystem);

    const handleOnUnitChange = (event: React.MouseEvent<HTMLElement>, newValue: Duration): void => {
        setDuration(newValue);
    };

    return (
        <Grid container>
            <Grid item container xs={12} justifyContent='center'>
                <ToggleButtonGroup
                    exclusive
                    value={duration}
                    onChange={handleOnUnitChange}
                    css={classes.toggleButtonGroup}
                    aria-label='Duration'
                >
                    {Object.values(Duration)
                        .filter((v) => isNaN(Number(v)))
                        .map((value) => (
                            <ToggleButton
                                key={value}
                                value={value}
                                css={classes.toggleButton}
                                selected={duration === value}
                                disabled={duration === value}
                            >
                                {capitalizeFirstLetter(value)}
                            </ToggleButton>
                        ))}
                </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} css={classes.backgroundContainer}>
                <Text variant='h3' color='activeSecondary' weight='bold' css={classes.textMarginBottom}>
                    Overall increased profits each {duration}
                </Text>
                <Text variant='h1' color='activeSecondary'>
                    ${Math.round(result.profitAdded).toLocaleString()}
                </Text>
            </Grid>
            <Grid item xs={6} css={classes.twoColumnContainer}>
                <Text variant='h3' weight='bold' color='activePrimary'>
                    ${roundToLocalString(result.revenueAdded)}
                </Text>
                <Text variant='body1'>in revenue gained each {duration}</Text>
            </Grid>
            <Grid item xs={6} css={classes.twoColumnContainer}>
                <Text variant='h3' weight='bold' color='activePrimary'>
                    ${roundToLocalString(result.costSaved)}
                </Text>
                <Text variant='body1'>in cost saved each {duration}</Text>
            </Grid>
            <Grid item xs={6} css={classes.twoColumnContainer}>
                <Text variant='h3' weight='bold' color='activePrimary'>
                    {roundToLocalString(result.distanceSaved)} {unitSystem === Unit.Imperial ? 'miles' : 'kilometers'}
                </Text>
                <Text variant='body1'>saved each {duration}</Text>
            </Grid>
            <Grid item xs={6} css={classes.twoColumnContainer}>
                <Text variant='h3' weight='bold' color='activePrimary'>
                    {roundToLocalString(result.hoursSaved)} hours
                </Text>
                <Text variant='body1'>saved each {duration}</Text>
            </Grid>
            <Grid item xs={12} css={[classes.backgroundContainer, { marginTop: '2.5rem' }]}>
                <Text variant='h3' color='activeSecondary' weight='bold' css={classes.textMarginBottom}>
                    Time to see ROI payback
                </Text>
                <Text variant='h1' color='activeSecondary'>
                    {getROIPaybackTime(result.timeToPayback)}
                </Text>
            </Grid>
            <Grid item xs={6} css={classes.twoColumnContainer}>
                <Text variant='h3' weight='bold' color='activePrimary'>
                    {roundToLocalString(result.fuelSaved)}
                </Text>
                <Text variant='body1'>{unitSystem === Unit.Imperial ? 'gallons' : 'liters'} of fuel saved</Text>
            </Grid>
            <Grid item xs={6} css={classes.twoColumnContainer}>
                <Text variant='h3' weight='bold' color='activePrimary'>
                    ${roundToLocalString(result.fuelCostsSaved)}
                </Text>
                <Text variant='body1'>in fuel costs saved</Text>
            </Grid>
            <Grid item xs={6} css={classes.twoColumnContainer}>
                <Text variant='h3' weight='bold' color='activePrimary'>
                    {roundToLocalString(result.overtimeHoursSaved)}
                </Text>
                <Text variant='body1'>hours of overtime saved</Text>
            </Grid>
            <Grid item xs={6} css={classes.twoColumnContainer}>
                <Text variant='h3' weight='bold' color='activePrimary'>
                    {roundTwoDecimal(result.emissionSaved)}%
                </Text>
                <Text variant='body1'>lower CO2 emissions</Text>
            </Grid>
            <Grid item xs={12} container justifyContent='center' css={classes.buttonContainer}>
                <PopoutButton
                    text='Download full report'
                    variant='contained'
                    customSectionKey='MultistepDemoForm'
                    params={[
                        { key: 'campaign_id', value: '701Pd00000G2iF6IAJ' },
                        {
                            key: FormParamKeys.OnFormSubmitSuccessFunction,
                            value: async () => await generateROIPdf(industryValue, unitSystem),
                        },
                    ]}
                    size='small'
                />
            </Grid>
        </Grid>
    );
}
