import { colors, breakpoints, v2Colors } from '@web-for-marketing/react-ui';

export const roiAdvanceFormStyles = {
    formInputContainer: {
        '&:nth-child(even)': {
            paddingRight: '1.35rem',
        },
        '&:nth-child(odd)': {
            paddingLeft: '1.35rem',
        },
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            '&:nth-child(even)': {
                paddingRight: 0,
            },
            '&:nth-child(odd)': {
                paddingLeft: 0,
            },
        },
    },
    formInput: {
        marginBottom: '2.4rem',
    },
    inputLabel: {
        marginBottom: '.8rem',
    },
    toggleButtonGroup: {
        marginBottom: '2.4rem',
    },
    toggleButton: {
        fontSize: '1.4rem',
        borderColor: 'transparent',
        color: v2Colors.text.activePrimary,
        backgroundColor: colors.interactive.inverse,
        borderRadius: '4px',
        padding: '0.8rem 1.2rem',
        textTransform: 'none',
        '&.MuiToggleButton-root.MuiToggleButtonGroup-grouped': {
            borderColor: colors.interactive.primary,
        },
        '&:hover': {
            backgroundColor: v2Colors.core.cloud,
            borderColor: colors.interactive.primary,
        },
        '&:focus': {
            backgroundColor: v2Colors.core.cloud,
            borderColor: colors.interactive.primary,
        },
        '&.Mui-selected': {
            color: colors.interactive.inverse,
            backgroundColor: colors.interactive.primary,
            borderColor: colors.interactive.primary,
            '&:hover': {
                backgroundColor: colors.interactive.primary,
            },
        },
    },
} as const;
